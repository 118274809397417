@mixin dashedBorder($color) {
    & .dashedBorder__rect {
        stroke: $color;
    }
}

.dashedBorder {
    width: 100%;
    height: 100%;
    @extend %reset;

    &__rect {
        @extend %reset;
        transition: $trans;
        stroke-width: 1;
        stroke-dasharray: .8rem .8rem;
        stroke: $colorSub;
    }
}