.innerPageHead {
    @extend %reset;
    @extend %col;
    width: 100%;
    align-items: center;

    &__title {
        @extend %reset;
        max-width: 100%;
        text-align: center;
        font-size: 6.5rem;
        line-height: 1.3;
        color: $colorSub;

        @include media(0, $mediaM) {
            font-size: 4rem;
        }
    }

    &__title+&__description {
        margin-top: 6rem;

        @include media(0, $mediaM) {
            margin-top: 2rem;
        }
    }

    &__description {
        @extend %reset;
        max-width: 100%;
        text-align: center;
        font-size: 4.25rem;
        line-height: 1.3;
        color: $colorSub;

        &._mediumSize {
            font-size: 3.3rem;
            line-height: 1.4;

            @include media(0, $mediaM) {
                font-size: 2.6rem;
            }
        }

        @include media(0, $mediaM) {
            font-size: 3.7rem;
            line-height: 1.2;
        }
    }

    &__button {
        @extend %reset;
        width: 35rem;
        margin-top: 5rem;
        margin-bottom: 3rem;

        @include media(0, $mediaM) {
            width: 28rem;
            margin-top: 4rem;
        }
    }
}