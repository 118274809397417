.form {
    @extend %reset;
    @extend %col;
    width: 100%;
    align-items: center;
    border-radius: 2.5rem;
    background: #fff;
    padding: 5.8rem;
    box-shadow: 0 1.6rem 3rem rgba(62, 47, 25, 0.1);
    position: relative;
    z-index: 1;
    transition: $trans;
    overflow: hidden;

    @include media(0, $mediaM) {
        padding: 2.5rem 2.5rem 5.5rem 2.5rem;
        border-radius: 2rem;
    }

    &._success {
        height: 35rem !important;
    }

    &__success {
        @extend %reset;
        @extend %col;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        justify-content: center;
        align-items: center;
        z-index: 10;
        padding: 5rem;
        transition: $trans;
        will-change: transform;

        &:not(._show) {
            opacity: 0;
            transform: scale(1.02);
        }

        @include media(0, $mediaM) {
            padding: 3rem;
        }

        &Content {
            @extend %reset;
            font-size: 2.5rem;
            line-height: 1.4;
            color: $colorDark;
            text-align: center;

            & span {
                display: block;
                color: $colorSub;
                margin-bottom: 1.5rem;
            }
        }
    }

    &__inner {
        @extend %reset;
        @extend %col;
        align-items: center;
        width: 70rem;

        @include media(0, $mediaM) {
            width: 100%;
        }
    }

    &__fields {
        @extend %reset;
        width: 100%;
        margin-bottom: 3.5rem;
    }

    &__field {
        @extend %reset;
        width: 100%;

        &+& {
            margin-top: 2.7rem;

            @include media(0, $mediaM) {
                margin-top: 2.3rem;
            }
        }
    }

    &__field:not(._checkbox)+&__field._checkbox {
        margin-top: 4rem;

        @include media(0, $mediaM) {
            margin-top: 3rem;
        }
    }

    &__required {
        @extend %reset;
        width: 100%;
        font-size: 2.2rem;
        line-height: 1.3;
        color: $colorDark;
        margin-bottom: 3rem;

        @include media(0, $mediaM) {
            font-size: 2.1rem;
        }
    }

    &__error {
        @extend %reset;
        transition: $trans;

        &._parent {
            max-width: 100%;

            &._static {
                @extend %col;
            }
        }

        &:not(._empty) {
            margin-bottom: 2rem;
        }
    }

    &__button {
        @extend %reset;
        width: 35rem;

        @include media(0, $mediaM) {
            width: 28rem;
        }
    }
}