﻿%reset {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'FuturaPT';
    font-weight: 500;
    line-height: 1;
    font-style: normal;
    letter-spacing: unset;
    text-decoration: none;
    list-style-type: none;
    background: none;
    border: 0;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    font-family: 'DolceCaffe';
}

@mixin bg($url, $size, $pos) {
    background: url($url)no-repeat;
    background-position: $pos;
    background-size: $size;
}

@mixin mediaMin($w) {
    @media screen and (min-width: $w) {
        @content;
    }
}

@mixin media($w1, $w2) {
    @media screen and (min-width: $w1) and (max-width: $w2) {
        @content;
    }
}

html {
    @extend %reset;
    width: 100%;
    position: relative;
    z-index: 1;
    font-size: calc(100vw / 179);

    @include media(0, $mediaM) {
        font-size: calc(100vw / 52);
        overflow: hidden;
    }
}

@mixin icon($color, $colorFill:null, $colorStroke:null, $colorAll:null) {
    @if $colorFill {
        & .icon__fill {
            fill: $colorFill;
        }
    }

    @if $colorStroke {
        & .icon__stroke {
            stroke: $colorStroke;
        }
    }

    @if $colorAll {
        & .icon__all {
            fill: $colorAll;
            stroke: $colorAll;
        }
    }

    @if $colorFill==null and $colorStroke==null and $colorAll==null {
        & .icon__fill {
            fill: $color;
        }

        & .icon__stroke {
            stroke: $color;
        }
    }
}

@mixin inParent($parent, $class) {
    .#{$parent}.#{$class} & {
        @content;
    }
}

@mixin blur($val) {
    & {
        -webkit-backdrop-filter: saturate(180%) blur($val);
        backdrop-filter: saturate(180%) blur($val);
    }
}

@mixin button($value:.98) {
    transition: $trans;

    @include mediaMin($mediaM + 1px) {
        will-change: transform;
    }

    &:active {
        transform: scale(#{$value});
    }
}

%MEDIA {
    &._mobile {
        @include mediaMin($mediaM + 1px) {
            display: none;
        }
    }

    &._desktop {
        @include media(0, $mediaM) {
            display: none;
        }
    }
}

br {
    @extend %MEDIA;
}

%section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

%inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 136rem;

    @include media(0, $mediaM) {
        width: 93vw;
    }
}

%row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

%col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

%click {
    @include mediaMin($mediaM + 1px) {
        cursor: pointer;
    }

    user-select: none;
}

%noscroll {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }
}

._desktopMedia {
    @include media(0, $mediaM) {
        display: none;
    }
}

._mobileMedia {
    @include mediaMin($mediaM + 1px) {
        display: none;
    }
}


._noWrap {
    white-space: nowrap;
}

._wrap {
    word-break: break-all;
}

%loader {
    @extend %reset;
    @extend %col;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: $trans;

    &:not(._show) {
        opacity: 0;
    }
}

%loaderItem {
    @extend %reset;
    margin: auto;
    width: 30px;
    height: 30px;
}

@mixin loadFont() {
    .body__main._fontsLoad & {
        @content;
    }
}

@mixin loader($color) {
    & .loader__item:after {
        background: $color;
    }

    & .loader__icon {
        @include icon($color);
    }
}

%innerPage {
    padding-top: 17rem;
    background-repeat: repeat-y;
    position: relative;
    z-index: 1;

    @include media(0, $mediaM) {
        padding-top: 14rem;
    }

    &::before {
        content: "";
        position: absolute;
        top: 14.5rem;
        left: 0;
        width: 100%;
        height: calc(100% - 14.5rem);
        z-index: -1;
        @include bg('../media/back-inner.png', 100% auto, center top);

        @include media(0, $mediaM) {
            top: 8.5rem;
            height: calc(100% - 8.5rem);
            @include bg('../media/back-inner-mob.png', 100% auto, center top);
        }
    }
}

@mixin loaderColor($color) {
    & .loader__itemInner {
        background: $color;
    }
}

%loader {
    @extend %col;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    transition: $trans;

    &:not(._show) {
        opacity: 0;
    }
}

%loaderItem {
    width: 3.5rem;
    height: 3.5rem;
    margin: auto;
}