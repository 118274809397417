.page {
    @extend %reset;
    width: 100%;
    // height: 10000px;
    // transform: translate(0, -2320px);

    &._inner {
        @extend %innerPage;
    }

    &__section {
        @extend %reset;
        width: 100%;
        position: relative;
        z-index: 1;

        &._withCat {
            z-index: 2;

            &::after {
                content: "";
                position: absolute;
                bottom: 100%;
                margin-bottom: -1rem;
                right: 20rem;
                width: 16.5rem;
                height: 16.5rem;
                @include bg('../media/cat-image.svg', contain, right bottom);
                z-index: 2;

                @include media(0, $mediaM) {
                    right: 2rem;
                    width: 12rem;
                    height: 12rem;
                    margin-bottom: -.5rem;
                }
            }
        }

        &._prizeAndWinners {
            @include bg('../media/back-4.png', 100% auto, center top);

            @include media(0, $mediaM) {
                background: none;
            }
        }
    }
}