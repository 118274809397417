.indexMainPrize {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 8rem 0 18rem 0;

    @include media(0, $mediaM) {
        padding: 0 0 6.5rem 0;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;
        position: relative;
        z-index: 1;

        @include media(0, $mediaM) {
            margin-top: -4rem;
        }

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 125rem;
            height: 100%;
            margin: 9rem 0 0 0;
            @include bg('../media/back-prize.png', contain, center);
            z-index: -1;

            @include media(0, $mediaM) {
                width: calc(100% + 7vw);
                margin: 7.5rem 0 0 0;
                @include bg('../media/back-prize-mob.png', contain, center);
            }
        }
    }

    &__title {
        @extend %reset;
        width: 77rem;
        height: 25.5rem;
        object-fit: contain;
        margin-bottom: 36rem;
        transition: $trans;

        @include media(0, $mediaM) {
            width: 44.5rem;
            height: 15rem;
            margin-bottom: 32.5rem;
        }
    }

    &:not(._ready) &__title {
        opacity: 0;
    }

    &__description {
        @extend %reset;
        text-align: center;
        max-width: 100%;
        font-size: 3.2rem;
        line-height: 1.3;
        color: $colorSub;
        margin-bottom: 3rem;

        @include media(0, $mediaM) {
            max-width: 85%;
            font-size: 2.9rem;
            line-height: 1.4;
            margin-bottom: 3.5rem;
        }
    }

    &__button {
        @extend %reset;
        width: 35rem;

        @include media(0, $mediaM) {
            width: 27rem;
        }
    }
}