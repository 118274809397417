.prizeCard {
    @extend %reset;
    width: 100%;
    height: 100%;
    box-shadow: 0px 1.6rem 3rem rgba(62, 47, 25, 0.1);
    background: #fff;
    border-radius: 2rem;
    padding: 2rem .8rem 3rem .8rem;

    &__inner {
        @extend %reset;
        @extend %col;
        width: 100%;
        height: 100%;
        align-items: center;
    }

    &__head {
        @extend %reset;
        @extend %col;
        width: 100%;
        height: 27.5rem;
        position: relative;
        padding: .5rem 0 3rem 0;
        margin-bottom: 2rem;
        align-items: center;

        @include media(0, $mediaM) {
            padding-top: 0;
        }

        &Inner {
            @extend %reset;
            width: 100%;
            height: 100%;
            position: relative;

            @include media(0, $mediaM) {
                width: 27rem;
            }
        }
    }

    &__error {
        @extend %reset;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 0;
        left: 0;
        color: #4291CA;
        font-size: 1.9rem;

        @include media(0, $mediaM) {
            font-size: 2.3rem;
        }
    }

    &__counter {
        @extend %reset;
        @extend %col;
        position: absolute;
        bottom: 2rem;
        right: 2rem;
        background: #FAC913;
        width: 6.5rem;
        height: 6.5rem;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        padding-left: 0.5rem;
        color: $colorSub;
        z-index: 2;

        @include media(0, $mediaM) {
            bottom: 1rem;
        }

        & span {
            font-size: 2.7rem;
            line-height: 1.2;
        }
    }

    &__image {
        @extend %reset;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__name {
        @extend %reset;
        max-width: 90%;
        text-align: center;
        font-size: 2.65rem;
        line-height: 1.3;
        color: $colorDark;
        margin-bottom: 1.5rem;

        @include media(0, $mediaM) {
            max-width: 80%;
            font-size: 2.9rem;
        }
    }

    &__status {
        @extend %reset;
        margin-top: auto;
        padding: 1.3rem 1.5rem 1rem 1.5rem;
        font-size: 1.9rem;
        border-radius: 36rem;

        @include media(0, $mediaM) {
            font-size: 2.6rem;
            padding: 1.6rem 2.5rem 1.3rem 2.5rem;
        }

        &._process {
            background: rgba(#FEDB20, .35);
            color: #D11919;
        }

        &._completed {
            background: rgba(#00A02F, .11);
            color: #00A02F;
        }

        &._received {
            background: rgba(#721185, .15);
            color: #721185;
        }

        &._error {
            background: rgba(#C10230, .11);
            color: #C10230;
        }

        &._link {
            @extend %click;
        }
    }
}