.topBar {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 2.5rem 0;
    background: $colorBack;

    &__inner {
        @extend %reset;
        @extend %inner;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
    }

    &__block {
        @extend %reset;

        &._logo {
            @include media(0, $mediaM) {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &._nav {
            @include media(0, $mediaM) {
                display: none;
            }
        }

        &._buttons {
            @include media(0, $mediaM) {
                display: none;
            }
        }
    }

    &__logo {
        @extend %reset;
        @extend %click;
        width: 12.5rem;
        pointer-events: visible;

        @include media(0, $mediaM) {
            width: 9.5rem;
        }

        &Icon {
            @extend %reset;
            width: 100%;
            display: block;
        }
    }

    &__nav {
        @extend %reset;
        @extend %row;
        align-items: center;

        &Item {
            @extend %reset;
            margin: 0 1.6rem;
            transition: $trans;

            &:not(._ready) {
                opacity: 0;
                pointer-events: none;
            }
        }

        &Link {
            @extend %reset;
            @extend %click;
            font-size: 2.1rem;
            color: $colorDark;
            @include button(.97);
            pointer-events: visible;

            &:hover {
                color: $colorMain;
            }
        }
    }

    &__buttons {
        @extend %reset;
        @extend %row;
        height: 4.5rem;
        align-items: center;
        position: relative;
        transition: $trans;
    }

    &__buttons._static &__button {
        position: relative;
        margin-left: 1.5rem;

        &:not(._show) {
            display: none;
        }
    }

    &__button {
        @extend %reset;
        pointer-events: visible;
        position: absolute;
        right: 0;
        top: 0;
        transition: $trans;
        white-space: nowrap;

        &:not(._show) {
            opacity: 0;
        }
    }

    &__menu {
        @extend %reset;
        @extend %col;
        width: 4rem;
        height: 2.5rem;
        position: relative;
        pointer-events: visible;

        display: none;

        @include media(0, $mediaM) {
            display: flex;
        }

        &Item {
            @extend %reset;
            @extend %col;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            transition: $trans;
            transform-origin: center;
            will-change: transform;

            &::before {
                content: "";
                width: 100%;
                height: 0.3rem;
                border-radius: 36rem;
                background: $colorDark;
                margin: auto;
            }

            &:nth-child(1) {
                transform: translate(0, -1rem);
            }

            &:nth-child(3) {
                transform: translate(0, 1rem);
            }
        }

        &._active &Item {
            &:nth-child(1) {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
                transform: translate(-5rem, 0);
            }

            &:nth-child(3) {
                transform: rotate(-45deg);
            }
        }
    }

    &__icon {
        @extend %reset;
        pointer-events: visible;
        width: 4rem;
        height: 4rem;
        object-fit: contain;
        display: none;

        @include media(0, $mediaM) {
            display: block;
        }
    }
}