.about {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding-bottom: 20rem;

    &__inner {
        @extend %reset;
        @extend %inner;
    }

    &__head {
        @extend %reset;
        width: 100%;
        margin-bottom: 6rem;

        @include media(0, $mediaM) {
            margin-bottom: 2.5rem;
        }
    }

    &__content {
        @extend %reset;
        width: 100%;
        position: relative;
        z-index: 1;
        transition: $trans;

        &Inner {
            @extend %reset;
            width: 100%;
            transition: $trans;
        }
    }

    &__loader {
        @extend %reset;
        @extend %loader;

        &Item {
            @extend %reset;
            @extend %loaderItem;
            @include loaderColor($colorSub);
        }
    }

    &:not(._ready) &__contentInner {
        opacity: 0;
    }

    &__block {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;

        &+& {
            margin-top: 10rem;

            @include media(0, $mediaM) {
                margin-top: 4rem;
            }
        }

        &Title {
            @extend %reset;
            max-width: 60%;
            text-align: center;
            font-size: 4.25rem;
            line-height: 1.4;
            color: $colorSub;
            margin-bottom: 4.5rem;

            @include media(0, $mediaM) {
                max-width: 90%;
                font-size: 2.95rem;
                margin-bottom: 3rem;
            }
        }

        &Content {
            @extend %reset;
            @extend %row;
            width: calc(100% + 3rem);
            flex-wrap: wrap;

            @include media(0, $mediaM) {
                width: 100%;
                flex-direction: column;
                flex-wrap: nowrap;
            }
        }

        &Card {
            @extend %reset;
            width: calc(100% / 3);
            padding: 0 1.5rem;
            margin-bottom: 3rem;

            @include media(0, $mediaM) {
                width: 100%;
                padding: 0;
            }
        }
    }

    &__market {
        @extend %reset;
        @extend %col;
        width: 100%;
        height: 25rem;
        padding: 7rem 5rem;
        border-radius: 2rem;
        background: #fff;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 1.6rem 3rem rgba(62, 47, 25, 0.1);

        @include media(0, $mediaM) {
            border-radius: 2.5rem;
        }

        &Logo {
            @extend %reset;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}