﻿@font-face {
    font-weight: 500;
    font-family: 'DolceCaffe';
    font-style: normal;
    src: local('DolceCaffe'), url('../fonts/DolceCaffe-Regular.otf');
}

@font-face {
    font-weight: 500;
    font-family: 'Inter';
    font-style: normal;
    src: local('Inter'), url('../fonts/Inter-Regular.ttf');
}