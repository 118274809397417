.error {
    @extend %reset;
    width: 100%;
    height: 100%;
    background: rgba(#FF9595, .24);
    color: #D11919;
    border-radius: 36rem;
    padding: 1.2rem 1.6rem .9rem 1.6rem;
    font-size: 2rem;
    line-height: 1.3;
    text-align: center;
    // white-space: nowrap;

    &._mediumSize {
        font-size: 1.7rem;

        @include media(0, $mediaM) {
            font-size: 2rem;
        }
    }

    @include media(0, $mediaM) {
        padding: 1.3rem 1.7rem 1rem 1.7rem;
        font-size: 2.1rem;
    }
}