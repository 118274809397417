.profilePrizes {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 4rem 0 17rem 0;

    @include media(0, $mediaM) {
        padding-top: 5rem;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
    }

    &__head {
        @extend %reset;
        width: 100%;
        margin-bottom: 4.5rem;
    }

    &__content {
        @extend %reset;
        width: 100%;
        position: relative;
        transition: $trans;

        &Inner {
            @extend %reset;
            width: 100%;
            transition: $trans;
        }
    }

    &:not(._ready) &__contentInner {
        opacity: 0;
    }

    &__loader {
        @extend %reset;
        @extend %loader;

        &Item {
            @extend %reset;
            @extend %loaderItem;
            @include loaderColor($colorSub);
        }
    }

    &__cards {
        @extend %reset;
        @extend %row;
        width: calc(100% + 3.5rem);
        margin-left: -1.75rem;
        flex-wrap: wrap;
        align-items: stretch;

        @include media(0, $mediaM) {
            flex-direction: column;
            width: 100%;
            margin-left: 0;
        }
    }

    &__card {
        @extend %reset;
        width: calc(100% / 4);
        margin-bottom: 3.5rem;
        padding: 0 1.75rem;

        @include media(0, $mediaM) {
            width: 100%;
            padding: 0;
        }
    }
}