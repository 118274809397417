.profileGame {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 5rem 0;

    @include media(0, $mediaM) {
        padding: 2rem 0;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 100%;
        height: 34rem;
        border-radius: 2rem;
        position: relative;
        @include bg('../media/game-banner-LK.png', cover, center);
        padding: 7.5rem 5rem 3.5rem 5rem;
        align-items: center;

        @include media(0, $mediaM) {
            border-radius: 2.5rem;
            padding: 3rem 6rem;
            height: 35.5rem;
            @include bg('../media/game-banner-LK-mob.png', cover, center);
        }

        &Inner {
            @extend %reset;
            @extend %col;
            width: 100%;
            transition: $trans;
            align-items: center;
        }
    }

    &:not(._ready) &__contentInner {
        opacity: 0;
    }

    &__loader {
        @extend %reset;
        @extend %loader;
        padding-top: 8rem;

        @include media(0, $mediaM) {
            padding-top: 11rem;
        }

        &Item {
            @extend %reset;
            @extend %loaderItem;
            @include loaderColor($colorMain);
        }
    }

    &__title {
        @extend %reset;
        max-width: 100%;
        text-align: center;
        font-size: 4.3rem;
        color: $colorDark;
        margin-bottom: 2.3rem;

        @include media(0, $mediaM) {
            font-size: 3.5rem;
            line-height: 1.2;
        }
    }

    &__description {
        @extend %reset;
        max-width: 100%;
        text-align: center;
        font-size: 2.6rem;
        color: $colorSub;
        margin: 1rem 0 1rem 0;
    }

    &__button {
        @extend %reset;
        width: 34.5rem;
        margin-bottom: 3.2rem;

        &._reg {
            width: auto;

            @include media(0, $mediaM) {
                width: auto;
            }
        }

        @include media(0, $mediaM) {
            width: 27rem;
        }
    }

    &__info {
        @extend %reset;
        color: #E42350;
        font-size: 2.1rem;
        transition: $trans;
        white-space: nowrap;

        &Wrapper {
            @extend %reset;
            background: #fff;
            border-radius: 36rem;
            height: 4.5rem;
            padding: 1.3rem 2rem 1rem 2rem;
            position: relative;
            z-index: 1;
            overflow: hidden;
        }
    }
}