.indexGame {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 9.5rem 0 16rem 0;
    background: #FAC913;
    position: relative;
    z-index: 1;

    @include media(0, $mediaM) {
        padding: 7rem 0 9rem 0;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        pointer-events: none;
        @include bg('../media/back-2.png', cover, center top);

        @include media(0, $mediaM) {
            @include bg('../media/back-mob-2.png', cover, center top);
        }
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        align-items: center;
    }

    &__title {
        @extend %reset;
        max-width: 60%;
        text-align: center;
        font-size: 4.3rem;
        line-height: 1.4;
        color: $colorSub;
        margin-bottom: 3.5rem;
        transition: $trans;

        @include media(0, $mediaM) {
            max-width: 100%;
            font-size: 3.2rem;
        }
    }

    &:not(._ready) &__title {
        opacity: 0;
    }

    &__content {
        @extend %reset;
        width: calc(100% + 6rem);
        height: 77rem;
        @include bg('../media/back-game.png', contain, center);
        margin-bottom: 4.5rem;

        @include media(0, $mediaM) {
            width: calc(100% + 7vw);
            height: 48rem;
            @include bg('../media/back-game-mob.png', contain, center);
        }
    }

    &__button {
        @extend %reset;
        width: 34.5rem;
        margin-left: 2rem;

        @include media(0, $mediaM) {
            width: 23rem;
            margin-left: 0;
        }
    }
}