.checkbox {
    @extend %reset;
    @extend %col;
    width: 100%;

    &__inner {
        @extend %reset;
        @extend %click;
        min-height: 4rem;
        display: block;
        position: relative;
        padding-left: 6rem;
        padding-top: .8rem;
        @include button(.98);

        @include media(0, $mediaM) {
            padding-left: 5.5rem;
        }
    }

    &__input {
        display: none;
    }

    &__point {
        @extend %reset;
        @extend %col;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        width: 4.2rem;
        height: 4.2rem;
        background: rgba($colorBack, .8);
        border-radius: .8rem;
        transition: $trans;
        z-index: 1;
        overflow: hidden;

        @include media(0, $mediaM) {
            width: 3.5rem;
            height: 3.5rem;
            border-radius: .6rem;
            top: 0;
            transform: translate(0, 0);
        }

        &Icon {
            @extend %reset;
            width: 3rem;
            height: 3rem;
            margin: auto;
            display: block;
            transition: $trans;
            opacity: 0;
            transform: translate(-3rem, 3rem) rotate(-45deg);
            will-change: transform;

            @include media(0, $mediaM) {
                width: 2.5rem;
                height: 2.5rem;
            }
        }
    }

    &__input:checked~&__point {
        background: #00A02F;
    }

    &__input:checked~&__point &__pointIcon {
        opacity: 1;
        transform: none;
    }

    &__content {
        @extend %reset;
        font-size: 1.9rem;
        line-height: 1.3;
        color: $colorDark;

        & a {
            @extend %reset;
            @extend %click;
            color: $colorDark;
            border-bottom: 1px solid $colorDark;
        }
    }
}