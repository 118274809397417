.profileCheques {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 5rem 0;

    @include media(0, $mediaM) {
        padding: 3rem 0;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
    }

    &__head {
        @extend %reset;
        width: 100%;
        margin-bottom: 4.5rem;

        @include media(0, $mediaM) {
            margin-bottom: 3rem;
        }
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;
    }

    &__table {
        @extend %reset;
        width: 100%;
        margin-bottom: 9.5rem;

        @include media(0, $mediaM) {
            margin-bottom: 7rem;
        }
    }

    &__info {
        @extend %reset;
        padding: 2.2rem 2.5rem 1.7rem 2.5rem;
        border-radius: 1.5rem;
        height: 8rem;
        background: $colorSub;
        border: 2px solid $colorDark;
        position: relative;
        z-index: 1;
        overflow: hidden;

        @include media(0, $mediaM) {
            height: 7rem;
        }

        &Loader {
            @extend %reset;
            @extend %loader;
            background: inherit;

            &Item {
                @extend %reset;
                @extend %loaderItem;
            }
        }

        @include media(0, $mediaM) {
            padding: 1.8rem 2rem 1.5rem 2rem;
            border-radius: 1.2rem;
        }

        &Inner {
            @extend %reset;
            @extend %row;
            height: 100%;
            font-size: 3.25rem;
            color: #fff;
            white-space: nowrap;
            align-items: center;

            & span {
                color: #FFD300;
            }

            @include media(0, $mediaM) {
                font-size: 2.3rem;
            }
        }
    }
}