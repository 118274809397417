.footer {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 15rem 0;
    background: $colorSub;

    @include media(0, $mediaM) {
        padding: 10rem 0;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        align-items: center;
    }

    &__nav {
        @extend %reset;
        @extend %row;
        align-items: center;
        margin-bottom: 9rem;

        @include media(0, $mediaM) {
            flex-direction: column;
            margin-bottom: 5rem;
        }

        &Item {
            @extend %reset;

            &+& {
                margin-left: 5rem;

                @include media(0, $mediaM) {
                    margin-left: 0;
                    margin-top: 5rem;
                }
            }
        }

        &Link {
            @extend %reset;
            @extend %click;
            font-size: 2.4rem;
            color: #fff;
        }
    }

    &__description {
        @extend %reset;
        max-width: 100%;
        text-align: center;
        font-size: 1.55rem;
        line-height: 2;
        color: #fff;
        font-family: 'Inter';

        @include media(0, $mediaM) {
            max-width: 90%;
            font-size: 1.8rem;
        }
    }
}