.productCard {
    @extend %reset;
    user-select: none;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;

    &._active &__inner {
        transform: rotateY(-180deg);
    }

    &__inner {
        @extend %reset;
        width: 100%;
        height: 100%;
        transition: $trans;
        will-change: transform;
        -webkit-transform-style: preserve-3d;
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 100%;
        height: 100%;
        align-items: center;
        padding: 3.5rem 3rem 4rem 3rem;
        border-radius: 2rem;
        will-change: transform;
        position: relative;
        z-index: 1;

        @include media(0, $mediaM) {
            border-radius: 2.5rem;
        }

        &:not(._back) {
            background: #fff;
            box-shadow: 0px 1.6rem 3rem rgba(62, 47, 25, 0.1);
            overflow: hidden;
        }

        &._back {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            background: #FAC913;
            // opacity: 0;
            // pointer-events: none;
            visibility: hidden;
            transform: rotateY(-180deg);
        }
    }

    &._showInfo &__content {
        // opacity: 0;
        // pointer-events: none;
        visibility: hidden;

        &._back {
            // opacity: 1;
            visibility: visible;
            // pointer-events: visible
        }
    }

    &__images {
        @extend %reset;
        @extend %row;
        width: 100%;
        height: 32rem;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 2.5rem;

        @include media(0, $mediaM) {
            height: 31.5rem;
            margin-bottom: 3rem;
        }
    }

    &__image {
        @extend %reset;
        width: 100%;
        height: 100%;
        object-fit: contain;

        &._min {
            width: 12rem;
            height: 12rem;
        }

        &._small {
            height: 90%;
        }

        &+& {
            margin-left: 2rem;
        }
    }

    &__images &__image {
        width: auto;
    }

    &__content._back &__image {
        margin-bottom: 1rem;
    }

    &__types {
        @extend %reset;
        @extend %row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 4rem;

        @include media(0, $mediaM) {
            margin-bottom: 2.5rem;
        }
    }

    &__content._back &__types {
        margin-top: auto;
        margin-bottom: 0;
    }

    &__type {
        @extend %reset;
        @extend %row;
        justify-content: center;
        min-width: 9.5rem;
        background: #FAC913;
        border-radius: 36rem;
        padding: 1.2rem 1.5rem .9rem 1.5rem;
        font-size: 2rem;
        font-weight: 500;
        color: $colorDark;
        margin: 0 1.5rem;

        @include media(0, $mediaM) {
            min-width: 10rem;
            font-size: 2.2rem;
            padding: 1.3rem 1.5rem 1rem 1.5rem;
        }
    }

    &__content._back &__type {
        background: #fff;
        color: $colorDark;
    }

    &__title {
        @extend %reset;
        max-width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 2.6rem;
        line-height: 1.4;
        color: $colorDark;
        margin-bottom: 1.7rem;

        @include media(0, $mediaM) {
            font-size: 2.6rem;
            margin-bottom: 2.5rem;
        }
    }

    &__content._back &__title {
        color: #721185;
        margin-bottom: 2.5rem;
    }

    &__aboutImage {
        @extend %reset;
        width: 11rem;
        margin-bottom: 1.5rem;

        @include media(0, $mediaM) {
            width: 13rem;
        }
    }

    &__description {
        @extend %reset;
        max-width: 90%;
        text-align: center;
        line-height: 1.3;
        font-size: 1.9rem;
        font-weight: 500;

        @include media(0, $mediaM) {
            font-size: 2.2rem;
        }

        & span {
            &._green {
                color: #00a02f;
            }

            &._red {
                color: #c10230;
            }
        }
    }

    &__content._back &__description {
        margin-bottom: 2rem;
    }

    &__btn {
        @extend %reset;
        @extend %click;
        color: #E42350;
        border-bottom: 1px solid rgba(#E42350, .72);
        font-size: 2.2rem;
        font-weight: 500;
        margin-top: auto;

        @include media(0, $mediaM) {
            font-size: 2.6rem;
        }
    }

    &._active &__content:not(._back) &__btn {
        pointer-events: none;
    }

    &:not(._active) &__content._back &__btn {
        pointer-events: none;
    }

    &__content._back &__btn {
        color: #fff;
        border-bottom: 1px solid rgba(#fff, .72);
        margin-top: 5rem;

        @include media(0, $mediaM) {
            margin-top: 3rem;
        }
    }

    &__buy {
        @extend %reset;
        width: 13rem;
        margin-top: 2rem;

        @include media(0, $mediaM) {
            margin-top: 3rem;
        }

        & .button {
            @include media(0, $mediaM) {
                padding-top: .9rem;
                padding-bottom: .6rem;
            }
        }
    }
}