.indexPrizes {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 17rem 0 11.5rem 0;
    position: relative;
    z-index: 1;
    @include bg('../media/back-3.png', 100% auto, center top);

    @include media(0, $mediaM) {
        padding: 9.5rem 0 16rem 0;
        @include bg('../media/back-mob-3.png', 100% auto, center top);

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include bg('../media/back-mob-4.png', 100% auto, center calc(100% + 1.5rem));
            z-index: -1;
        }
    }

    &__inner {
        @extend %reset;
        @extend %inner;
    }

    &__block {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;

        &+& {
            margin-top: 13.5rem;
        }

        &Head {
            @extend %reset;
            @extend %col;
            align-items: center;
            margin-bottom: 2.5rem;
            position: relative;

            @include media(0, $mediaM) {
                margin-bottom: 6rem;
            }

            &Prize {
                @extend %reset;
                position: absolute;
                left: 100%;
                top: 0;
                margin: -9rem 0 0 10rem;

                @include media(0, $mediaM) {
                    margin: -3.5rem 0 8rem 0;
                    position: relative;
                    left: 0;
                }

                &Image {
                    @extend %reset;
                    width: 29rem;
                    height: 29rem;
                    margin-bottom: 1.5rem;

                    @include media(0, $mediaM) {
                        width: 24.5rem;
                        height: 24.5rem;
                        margin-bottom: 2rem;
                    }
                }

                &Description {
                    @extend %reset;
                    max-width: 100%;
                    font-size: 2.7rem;
                    text-align: center;
                    line-height: 1.35;
                    color: $colorDark;

                    @include media(0, $mediaM) {
                        font-size: 3rem;
                    }
                }
            }

            &Offer {
                @extend %reset;
                @extend %col;
                position: absolute;
                left: 100%;
                margin-left: -8rem;
                top: 8rem;
                width: 25rem;
                height: 20rem;
                z-index: 2;
                padding: 2rem 1rem 2.5rem 5.75rem;

                @include media(0, $mediaM) {
                    left: 0;
                    position: relative;
                    margin-left: 0;
                    top: 0;
                    width: auto;
                    height: auto;
                    padding: 0;
                    align-items: center;
                    margin-top: -3.5rem;
                    margin-bottom: 6rem;
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    @include bg('../media/bubble-date.svg', contain, left top);
                    width: 25rem;
                    height: 20rem;
                    z-index: -1;

                    @include media(0, $mediaM) {
                        display: none;
                    }
                }

                &Title {
                    @extend %reset;
                    font-size: 2.1rem;
                    line-height: 1.2;
                    color: #FFD300;
                    margin-bottom: 0.5rem;

                    @include media(0, $mediaM) {
                        font-size: 2.3rem;
                        line-height: 1.3;
                        color: $colorDark;
                        text-align: center;
                        margin-bottom: 1.5rem;
                    }
                }

                &Description {
                    @extend %reset;
                    max-width: 100%;
                    font-size: 2.7rem;
                    color: #fff;

                    @include media(0, $mediaM) {
                        width: 27rem;
                        padding: 1.2rem 2rem 1rem 2rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        border-radius: 36rem;
                        font-size: 2.9rem;
                        border: 2px solid $colorDark;
                        background: $colorSub;
                        color: #FFD300;
                    }
                }
            }
        }

        &._start &Head {
            margin-left: -44rem;

            @include media(0, $mediaM) {
                margin-left: 0;
            }
        }

        &._end &Head {
            margin-bottom: 1rem;

            @include media(0, $mediaM) {
                margin-bottom: 5rem;
            }
        }

        &Title {
            @extend %reset;
            width: 61rem;
            height: 21rem;
            object-fit: contain;
            margin-bottom: 6rem;

            @include media(0, $mediaM) {
                width: 43rem;
                height: 15rem;
                margin-bottom: 3rem;
            }
        }

        &._end &Title {
            width: 65rem;
            margin-bottom: 1rem;

            @include media(0, $mediaM) {
                width: 43rem;
                height: 14rem;
            }
        }

        &Description {
            @extend %reset;
            font-size: 3.2rem;
            line-height: 1.4;
            color: $colorSub;
            text-align: center;

            @include media(0, $mediaM) {
                font-size: 2.9rem;
            }

            & span._min {
                transform-origin: center top;
                transform: translate(0, -1rem) scale(.7);
                display: inline-block;
            }
        }

        &Content {
            @extend %reset;
            @extend %row;
            width: calc(100% + 6rem);
            justify-content: center;

            @include media(0, $mediaM) {
                width: 100%;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
        }

        &._end &Card {
            margin: 0 .5rem;

            @include media(0, $mediaM) {
                margin: 0;

                &:not(:last-child) {
                    margin-bottom: 4.3rem;
                }
            }

            &Head {
                margin-bottom: 1.5rem;
            }

            &Description {
                white-space: nowrap;
            }
        }

        &Card {
            @extend %reset;
            width: 34rem;
            margin: 0 3rem;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }

            @include media(0, $mediaM) {
                width: 80%;
                margin: 0;

                &+& {
                    margin-top: 4rem;
                }
            }

            &Head {
                @extend %reset;
                width: 100%;
                height: 24rem;
                margin-bottom: 2.5rem;

                @include media(0, $mediaM) {
                    height: 26.5rem;
                    margin-bottom: 1.5rem;
                }
            }

            &Image {
                @extend %reset;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            &Content {
                @extend %reset;
                width: 100%;
            }

            &Description {
                @extend %reset;
                max-width: 100%;
                font-size: 2.7rem;
                text-align: center;
                line-height: 1.35;
                color: $colorDark;

                @include media(0, $mediaM) {
                    font-size: 2.9rem;
                }

                & span._min {
                    transform-origin: center top;
                    transform: translate(0, -.5rem) scale(.7);
                    display: inline-block;
                    margin-left: 0.5rem;
                }
            }
        }
    }
}