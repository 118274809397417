.faqHeader {
    @extend %reset;
    @extend %section;
    padding-bottom: 5rem;

    &__inner {
        @extend %reset;
        @extend %inner;
    }

    &__head {
        @extend %reset;
        width: 100%;
        margin-bottom: 6rem;

        @include media(0, $mediaM) {
            margin-bottom: 3rem;
        }
    }

    &__content {
        @extend %reset;
        width: 100%;
        counter-reset: cardCounter;
        position: relative;
        transition: $trans;

        &Inner {
            @extend %reset;
            width: 100%;
            position: relative;
            transition: $trans;
        }
    }

    &__loader {
        @extend %reset;
        @extend %loader;

        &Item {
            @extend %reset;
            @extend %loaderItem;
            @include loaderColor($colorSub);
        }
    }

    &:not(._ready) &__contentInner {
        opacity: 0;
    }

    &__card {
        @extend %reset;
        width: 100%;
        border-radius: 2rem;
        background: #FAC913;
        transition: $trans;
        box-shadow: 0px 1.6rem 3rem rgba(62, 47, 25, 0.1);
        counter-increment: cardCounter;
        @include button(.98);
        position: relative;
        z-index: 1;
        overflow: hidden;

        &+& {
            margin-top: 3rem;

            @include media(0, $mediaM) {
                margin-top: 2rem;
            }
        }

        &._active {
            background: #fff;
        }

        &Head {
            @extend %reset;
            @extend %click;
            width: 100%;
            padding: 3.5rem 10rem 3.5rem 10.5rem;
            position: relative;

            @include media(0, $mediaM) {
                padding: 2.4rem 8rem 2.4rem 9rem;
            }

            &::before {
                @extend %reset;
                @extend %col;
                content: counter(cardCounter);
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                left: 2.5rem;
                width: 5.7rem;
                height: 5.7rem;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 3rem;
                transition: $trans;
                background: $colorSub;
                color: #fff;

                @include media(0, $mediaM) {
                    width: 5rem;
                    height: 5rem;
                    left: 2rem;
                }
            }

            &Icon {
                @extend %reset;
                position: absolute;
                top: 50%;
                transform: translate(0, -50%);
                right: 3.5rem;
                width: 2.5rem;
                height: 2.5rem;
                z-index: 2;
                @include icon($colorSub);
                transition: $trans;

                @include media(0, $mediaM) {
                    top: 3rem;
                    right: 2rem;
                    transform: translate(0, 0);
                }
            }
        }

        &._active &Head {
            &::before {
                background: #FEDB20;
                color: $colorSub;
            }

            &Icon {
                transform: translate(0, -50%) rotate(180deg);
                @include icon($colorMain);

                @include media(0, $mediaM) {
                    transform: rotate(180deg);
                }
            }
        }

        &Title {
            @extend %reset;
            max-width: 100%;
            color: $colorSub;
            font-size: 3.25rem;
            line-height: 1.3;
            transition: $trans;

            @include media(0, $mediaM) {
                font-size: 2.7rem;
            }
        }

        &._active &Title {
            color: $colorDark;
        }

        &:not(._active) &ContentInner {
            transform: translate(0, 1rem);
        }

        &Content {
            @extend %reset;
            width: 100%;
            height: 0;
            transition: $trans;

            &Inner {
                @extend %reset;
                @extend %col;
                width: 100%;
                padding: 2rem 6rem 1rem 3.7rem;
                transition: $trans;

                @include media(0, $mediaM) {
                    padding: 0rem 3rem 0 2.2rem;
                }

                & p {
                    @extend %reset;
                    font-size: 2.2rem;
                    line-height: 1.3;
                    font-weight: normal;
                    color: $colorDark;
                    margin-bottom: 1.7rem;
                }

                & b {
                    @extend %reset;
                    font-weight: bold;
                }

                & a {
                    @extend %reset;
                    font-size: 2.2rem;
                    line-height: 1.3;
                    font-weight: normal;
                    color: $colorSub;
                    border-bottom: 1px solid $colorSub;
                    @extend %click;
                }

                & ol,
                & ul {
                    padding-left: 3.5rem;

                    @include media(0, $mediaM) {
                        padding-left: 0;
                    }
                }

                & ol {
                    @extend %reset;
                    counter-reset: counterFaq;
                }

                & ul {
                    @extend %reset;
                }

                & li {
                    @extend %reset;
                    font-size: 2.2rem;
                    line-height: 1.3;
                    font-weight: normal;
                    color: $colorDark;
                    position: relative;
                    padding-left: 4.5rem;
                    margin-bottom: 1.7rem;
                }

                & ol li {
                    counter-increment: counterFaq;

                    &::before {
                        @extend %reset;
                        content: counter(counterFaq);
                        position: absolute;
                        top: -.4rem;
                        left: 0;
                        font-size: 2rem;
                        width: 3.3rem;
                        height: 3.3rem;
                        border-radius: 50%;
                        @extend %col;
                        justify-content: center;
                        align-items: center;
                        line-height: 1.3;
                        padding-top: .2rem;
                        font-weight: normal;
                        color: $colorSub;
                        background: #FEDB20;
                    }
                }

                & ul li {
                    counter-increment: counterFaq;

                    @include media(0, $mediaM) {
                        padding-left: 3.5rem;
                    }

                    &::before {
                        @extend %reset;
                        content: "•";
                        position: absolute;
                        top: -.2rem;
                        left: 2.2rem;
                        font-size: 2.2rem;
                        line-height: 1.3;
                        font-weight: normal;
                        color: $colorSub;

                        @include media(0, $mediaM) {
                            left: 1rem;
                        }
                    }
                }

                & img {
                    max-width: 44rem;
                    margin-bottom: 2rem;

                    @include media(0, $mediaM) {
                        width: 100%;
                    }
                }
            }
        }
    }
}