.field {
    @extend %reset;
    width: 100%;

    &__support {
        @extend %reset;
        font-size: 2.2rem;
        line-height: 1.3;
        color: $colorDark;
        margin-bottom: 1.2rem;

        @include media(0, $mediaM) {
            font-size: 2.1rem;
            margin-bottom: 0.8rem;
        }
    }

    &__box {
        @extend %reset;
        width: 100%;
        height: 6rem;
        position: relative;

        @include media(0, $mediaM) {
            height: 6.5rem;
        }
    }

    &._upload &__box {
        height: auto;

        @include media(0, $mediaM) {
            height: auto;
        }
    }

    &._area &__box {
        height: 26rem;

        @include media(0, $mediaM) {
            height: 23.5rem;
        }
    }

    &__input {
        @extend %reset;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        background: rgba($colorBack, .8);
        color: $colorSub;
        font-size: 2.3rem;
        line-height: 1.3;
        padding: 1rem 2rem;

        @include media(0, $mediaM) {
            font-size: 2.7rem;
        }
    }

    &._area &__input {
        padding: 2rem;
        resize: none;
    }

    &__list {
        @extend %reset;
        overflow-y: auto;
        position: absolute;
        top: 100%;
        margin-top: 0.5rem;
        z-index: 2;
        width: 100%;
        max-height: 20rem;
        box-shadow: 0 0 1.2rem rgba(#61636A, .15);
        border-radius: 1.5rem;
        background: #fff;
        transition: $trans;

        &:not(._show) {
            opacity: 0;
            transform: translate(0, 1rem);
            pointer-events: none;
        }

        &Inner {
            @extend %reset;
            width: 100%;
            padding: 2rem;
        }

        &Support {
            @extend %reset;
            max-width: 100%;
            font-size: 1.9rem;
            color: $colorDark;
            margin-bottom: 2.5rem;
        }

        &Items {
            @extend %reset;
            width: 100%;
        }

        &Item {
            @extend %reset;
            @extend %click;
            width: 100%;
            font-size: 2.3rem;
            line-height: 1.3;
            color: $colorSub;
            transition: $trans;

            &:hover {
                opacity: .5;
            }

            &+& {
                margin-top: 1.5rem;
            }

            & span {
                color: $colorMain;
            }
        }
    }
}