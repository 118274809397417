.profileHeader {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding-bottom: 5rem;

    @include media(0, $mediaM) {
        padding-bottom: 3rem;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
    }

    &__head {
        @extend %reset;
        width: 100%;
        margin-bottom: 6rem;

        @include media(0, $mediaM) {
            margin-bottom: 3rem;
        }
    }

    &__content {
        @extend %reset;
        width: 100%;
        border-radius: 1.8rem;
        box-shadow: 0px 1.6rem 3rem rgba(62, 47, 25, 0.1);
        padding: 3.7rem 3.4rem;
        background: #fff;

        @include media(0, $mediaM) {
            padding: 2.6rem 1.8rem;
            border-radius: 2.5rem;
        }

        &Inner {
            @extend %reset;
            @extend %col;
            width: 100%;
            position: relative;
        }

        &Name {
            @extend %reset;
            max-width: 100%;
            font-size: 3.2rem;
            color: $colorDark;
            margin-bottom: 1.2rem;

            @include media(0, $mediaM) {
                font-size: 2.7rem;
                margin-bottom: 1.5rem;
            }
        }

        &Info {
            @extend %reset;
            @extend %row;
            align-items: center;

            @include media(0, $mediaM) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        &Id {
            @extend %reset;
            padding: 1.5rem 1.5rem 1.2rem 1.5rem;
            border-radius: 36rem;
            font-size: 2.1rem;
            color: $colorDark;
            background: #FEDB20;

            @include media(0, $mediaM) {
                font-size: 1.9rem;
                padding: 1.2rem 3rem .8rem 3rem;
            }
        }

        &Btn {
            @extend %reset;
            @extend %click;
            font-size: 2.15rem;
            color: $colorSub;
            border-bottom: 1px solid $colorSub;
            margin-left: 2rem;
            @include button(.97);

            @include media(0, $mediaM) {
                font-size: 1.85rem;
                margin-left: 0;
                margin-top: 2.5rem;
            }
        }

        &Actions {
            @extend %reset;
            @extend %row;
            position: absolute;
            bottom: -.5rem;
            right: 0;
            align-items: center;
            z-index: 2;

            @include media(0, $mediaM) {
                bottom: -.8rem;
            }
        }

        &Action {
            @extend %reset;
            transition: $trans;

            &:not(._show) {
                opacity: 0;
            }

            & .button {
                padding-left: 2.5rem;
                padding-right: 2.5rem;

                @include media(0, $mediaM) {
                    font-size: 1.9rem;
                    padding: .7rem 1.5rem .5rem 1.5rem;
                }
            }

            &+& {
                margin-left: 1.2rem;
            }
        }
    }
}