.popup {
    width: 100%;
    height: 100%;
    padding: 5.5rem 0;
    overflow-y: auto;
    @extend %reset;
    @extend %noscroll;
    @extend %col;

    &__inner {
        // height: 73.5rem;
        position: relative;
        width: 72rem;
        margin: auto;
        @extend %reset;

        @include media(0, $mediaM) {
            width: 82vw;
            margin: 0 auto;
        }

        &Box {
            position: relative;
            z-index: 1;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 4rem 4.2rem;
            overflow: hidden;
            background: #fff;
            border-radius: 2rem;
            @extend %reset;
            @extend %col;

            @include media(0, $mediaM) {
                padding: 4.5rem 1.8rem 4rem 1.8rem;
                border-radius: 2rem;
            }
        }
    }

    &._gamePopup &__inner {
        width: 100rem;
        height: 66rem;

        @include media(0, $mediaM) {
            width: 100%;
            height: 60rem;
            margin: auto;
        }

        &Box {
            padding: 0;
            background: transparent;
            background: #fac913;

            @include media(0, $mediaM) {
                padding: 0;
            }
        }
    }

    &__close {
        position: absolute;
        bottom: 100%;
        left: 100%;
        width: 5rem;
        height: 5rem;
        margin: 0 0 0rem 0rem;
        background: #fac913;
        border-radius: 50%;
        @extend %reset;
        @extend %click;
        @extend %col;

        @include media(0, $mediaM) {
            width: 4rem;
            height: 4rem;
            margin: 0 0 -0.5rem -0.7rem;
        }

        &Icon {
            display: block;
            width: 2.2rem;
            height: 2.2rem;
            margin: auto;
            @extend %reset;
            @include icon($colorSub);

            @include media(0, $mediaM) {
                width: 1.8rem;
                height: 1.8rem;
            }
        }
    }

    &._gamePopup &__close {
        @include media(0, $mediaM) {
            right: 1rem;
            left: auto;
            margin: 0 0 1rem 0;
        }
    }

    &__blocks {
        position: relative;
        width: 100%;
        transition: $trans;
        @extend %reset;
    }

    &__block {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translate(0, -50%);
        transition: $trans;
        @extend %reset;

        &:not(._show) {
            opacity: 0;

            &._prev {
                transform: translate(-10rem, -50%);
            }

            &._next {
                transform: translate(10rem, -50%);
            }
        }
    }

    &__blocks._static &__block {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__head {
        align-items: center;
        width: 100%;
        margin-bottom: 4rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            margin-bottom: 2.5rem;
        }

        &Title {
            max-width: 100%;
            color: $colorDark;
            font-size: 3.25rem;
            text-align: center;
            @extend %reset;

            @include media(0, $mediaM) {
                font-size: 2.95rem;
            }
        }

        &Description {
            max-width: 100%;
            margin-top: 1.3rem;
            color: $colorDark;
            font-size: 1.95rem;
            line-height: 1.3;
            text-align: center;
            @extend %reset;

            @include media(0, $mediaM) {
                margin-top: 1rem;
                font-size: 2.1rem;
            }
        }
    }

    &__content {
        align-items: center;
        width: 100%;
        @extend %reset;
        @extend %col;

        &._minHeight {
            justify-content: center;
            min-height: 25rem;
        }
    }

    &__fields {
        width: 100%;
        margin-bottom: 2rem;
        @extend %reset;

        @include media(0, $mediaM) {
            margin-bottom: 2.5rem;
        }
    }

    &__field {
        width: 100%;
        @extend %reset;
        @extend %col;

        & + & {
            margin-top: 3rem;

            @include media(0, $mediaM) {
                margin-top: 1.8rem;
            }
        }

        &Link {
            margin: 1rem 0 0 auto;
            color: $colorSub;
            font-size: 1.9rem;
            border-bottom: 1px solid $colorSub;
            @extend %reset;
            @extend %click;

            @include media(0, $mediaM) {
                margin-top: 1.3rem;
                font-size: 2.1rem;
            }
        }
    }

    &__socials {
        align-items: center;
        width: 100%;
        margin-bottom: 3rem;
        @extend %reset;
        @extend %col;

        @include media(0, $mediaM) {
            margin-bottom: 2rem;
        }

        &Support {
            @extend .field__support;
        }

        &Items {
            align-items: center;
            justify-content: center;
            width: 100%;
            @extend %reset;
            @extend %row;

            @include media(0, $mediaM) {
                flex-wrap: wrap;
            }
        }

        &Item {
            align-items: center;
            justify-content: center;
            width: 5.7rem;
            height: 5.7rem;
            margin: 0 0.5rem;
            padding-top: 0.3rem;
            color: $colorDark;
            font-size: 1.9rem;
            background: #f3f3f3;
            border-radius: 1rem;
            @extend %reset;
            @extend %click;
            @extend %row;
            @include button(0.98);

            @include media(0, $mediaM) {
                margin-bottom: 1.4rem;
            }

            &Icon {
                width: 3rem;
                height: 3rem;
                margin-top: -0.3rem;

                @extend %reset;

                @include media(0, $mediaM) {
                    width: 2.8rem;
                    height: 2.8rem;
                }
            }
        }
    }

    &__codes {
        align-items: center;
        width: 100%;
        padding: 1rem 0 5rem 0;
        @extend %reset;
        @extend %col;

        &Description {
            max-width: 75%;
            margin-bottom: 4rem;
            color: $colorDark;
            font-size: 1.95rem;
            line-height: 1.3;
            text-align: center;
            @extend %reset;

            @include media(0, $mediaM) {
                max-width: 90%;
                font-size: 2.1rem;
            }

            & span {
                color: $colorMain;
                white-space: nowrap;
            }

            & a {
                color: $colorSub;
                border-bottom: 1px solid $colorSub;
                @extend %reset;
                @extend %click;
            }
        }

        &Inputs {
            position: relative;
            z-index: 1;
            @extend %reset;

            &Loader {
                z-index: 10;
                will-change: transform;
                @extend %reset;
                @extend %loader;

                &:not(._show) {
                    transform: scale(1.03);
                }

                &Item {
                    @extend %reset;
                    @extend %loaderItem;
                    @include loaderColor($colorMain);
                }
            }

            &Inner {
                align-items: center;
                transition: $trans;
                will-change: transform;
                @extend %reset;
                @extend %row;
            }

            &._loading &Inner {
                transform: scale(0.97);
            }
        }

        &Inputs._loading &Input {
            opacity: 0.5;
            pointer-events: none;
        }

        &Input {
            width: 6rem;
            height: 6rem;
            margin: 0 0.3rem;
            color: $colorSub;
            font-size: 3rem;
            text-align: center;
            background: rgba(#f9eddb, 0.8);
            border-radius: 1rem;
            transition: $trans;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 5.5rem;
                height: 5.5rem;
                font-size: 2.3rem;
                border-radius: 1.5rem;
            }
        }
    }

    &__scan {
        position: relative;
        z-index: 1;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 23rem;
        background: rgba(#f9eddb, 0.8);
        border-radius: 1.5rem;
        @extend %reset;
        @extend %click;
        @extend %col;
        @include button(0.98);

        &Back {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            @extend %reset;

            & .dashedBorder__rect {
                stroke: #5f2589;
            }
        }

        &:hover .dashedBorder__rect {
            animation: runStroke 7s infinite linear;
        }

        &Icon {
            width: 5rem;
            height: 5rem;
            margin-bottom: 3rem;
            @extend %reset;
        }

        &Description {
            max-width: 100%;
            color: #5f2589;
            font-size: 2.3rem;
            line-height: 1.3;
            text-align: center;
            @extend %reset;
        }
    }

    &__qrError {
        align-items: center;
        width: 100%;
        padding: 2rem 0 4rem 0;
        @extend %reset;
        @extend %col;

        &Title {
            max-width: 100%;
            margin-bottom: 1.5rem;
            color: $colorDark;
            font-size: 2.1rem;
            @extend %reset;
        }

        &Content {
            margin-bottom: 4rem;
            @extend %reset;
        }

        &Button {
            height: 6.5rem;
            @extend %reset;
        }
    }

    &__qrArea {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 40rem;
        margin-bottom: 2rem;
        overflow: hidden;
        background: rgba($colorBack, 0.8);
        border-radius: 1.5rem;
        @extend %reset;

        @include media(0, $mediaM) {
            margin-bottom: 0;
        }

        &Loader {
            @extend %reset;
            @extend %loader;
            @include loaderColor($colorSub);

            &Item {
                width: 4rem;
                height: 4rem;
                @extend %reset;
                @extend %loaderItem;
            }
        }

        & canvas {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @extend %reset;
        }
    }

    &__form {
        width: 100%;
        padding-bottom: 1rem;
        @extend %reset;

        &Fields {
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            @extend %reset;
            @extend %row;
        }

        &Field {
            width: calc(50% - 1.2rem);
            margin-bottom: 2.4rem;
            @extend %reset;

            @include media(0, $mediaM) {
                width: 100%;
            }

            &._file {
                width: 100%;
            }

            &Support {
                @extend .field__support;
            }

            &Content {
                justify-content: space-between;
                width: 100%;
                height: 6rem;
                @extend %reset;
                @extend %row;
            }

            &._file &Content {
                height: auto;
            }

            &Select {
                width: calc(50% - 0.7rem);
                height: 100%;
                @extend %reset;
            }
        }
    }

    &__foot {
        align-items: center;
        width: 100%;
        margin-top: auto;
        @extend %reset;
        @extend %col;
    }

    &__error {
        @extend %reset;

        &._parent {
            max-width: 100%;

            &._static {
                @extend %col;
            }
        }
    }

    &__buttons {
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 3rem;
        @extend %reset;
        @extend %row;

        @include media(0, $mediaM) {
            flex-direction: column-reverse;
            justify-content: flex-start;
        }
    }

    &__button {
        width: calc(50% - 1.5rem);
        height: 6.5rem;
        @extend %reset;

        @include media(0, $mediaM) {
            width: calc(100% - 3rem);
        }

        &._height {
            width: 24.5rem;
            height: 9rem;

            @include media(0, $mediaM) {
                width: 100%;
                height: auto;

                & .button {
                    font-size: 2.5rem;
                }
            }
        }

        &._wide {
            width: auto;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        & .button {
            padding-right: 0;
            padding-left: 0;
        }

        &._wide .button {
            padding-right: 3rem;
            padding-left: 3rem;

            @include media(0, $mediaM) {
                padding-right: 1rem;
                padding-left: 1rem;
            }
        }

        & + & {
            margin-left: 1.5rem;

            @include media(0, $mediaM) {
                margin-bottom: 2.3rem;
                margin-left: 0;
            }
        }
    }

    &__success {
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 23rem;
        margin: 2rem 0 3rem 0;
        padding: 3rem;
        background: rgba(#ffd300, 0.2);
        border: 2px solid #ffd300;
        border-radius: 1.7rem;
        @extend %reset;
        @extend %col;

        &Title {
            max-width: 100%;
            margin-bottom: 2.5rem;
            color: $colorSub;
            font-size: 2.7rem;
            text-align: center;
            @extend %reset;
        }

        &Description {
            max-width: 100%;
            color: $colorDark;
            font-size: 2.1rem;
            line-height: 1.3;
            text-align: center;
            @extend %reset;
        }
    }

    &__game {
        position: relative;
        width: 100%;
        height: 100%;
        @extend %reset;
    }
}
