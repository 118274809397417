.table {
    @extend %reset;
    width: 100%;

    &._cheques {
        @include media(0, $mediaM) {
            border-radius: 1rem;
            background: #fff;
        }
    }

    &__content {
        @extend %reset;
        width: 100%;
        position: relative;

        &Inner {
            @extend %reset;
            @extend %col;
            width: 100%;
            position: relative;
            transition: $trans;
            background: #fff;
            border-radius: 0 0 1.5rem 1.5rem;
            z-index: 2;
            overflow: hidden;
        }
    }

    &__loader {
        @extend %reset;
        @extend %loader;
        z-index: 10;

        &Item {
            @extend %reset;
            @extend %loaderItem;
            @include loaderColor($colorSub);
        }
    }

    &__empty {
        @extend %reset;
        @extend %loader;
        z-index: 9;
        justify-content: center;
        align-items: center;
        will-change: transform;
        padding: 3rem;

        &:not(._show) {
            transform: scale(1.05);
        }

        &Inner {
            @extend %reset;
            width: 100%;
        }

        &Title {
            @extend %reset;
            max-width: 100%;
            font-size: 2.6rem;
            color: $colorDark;
            text-align: center;
            line-height: 1.4;
        }
    }

    &__contentInner._static &__rowWrapper {
        position: relative;

        &:not(._show) {
            display: none;
        }
    }

    &__rowWrapper:not(._show) &__row {
        opacity: 0;
    }

    &__row {
        @extend %reset;
        @extend %row;
        width: 100%;
        align-items: stretch;
        transition: $trans;

        &Wrapper {
            @extend %reset;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            transition: $trans;
        }

        @include media(0, $mediaM) {
            justify-content: space-between;
            padding: 2rem 1.75rem;
        }

        &._head {
            background: $colorSub;
            height: 9rem;
            border-radius: 1rem 1rem 0 0;
        }

        &Wrapper._last & {
            // border-radius: 0 0 1.5rem 1.5rem;
        }

        &:not(._head) {
            background: #FAC913;

            @include media(0, $mediaM) {
                // border-radius: .75rem;
            }

            &:not(._odd) {
                background: #fff;
            }
        }
    }

    &._winners &__row {
        &:not(._head) {
            @include media(0, $mediaM) {
                background: #fff;

                &._odd {
                    background: #FAC913;
                }
            }
        }
    }

    &._winners &__row._head {
        @include media(0, $mediaM) {
            display: none;
        }
    }

    &__col {
        @extend %reset;
        @extend %col;
        height: 100%;
        justify-content: center;
        padding: 1.5rem 2rem 1.3rem 2rem;

        @include media(0, $mediaM) {
            justify-content: flex-start;
            padding: 0;

            &:empty {
                display: none;
            }

            &+& {
                margin-top: 1rem;
            }
        }

        &Wrapper {
            @extend %reset;
            @extend %row;
            font-size: 2.6rem;
            line-height: 1.3;
            align-items: stretch;

            @include media(0, $mediaM) {
                flex-direction: column;
                align-items: flex-start;
                font-size: 2.2rem;
            }
        }

        &Inner {
            @extend %reset;
            font-size: 2.6rem;
            line-height: 1.3;
            text-align: center;

            @include media(0, $mediaM) {
                font-size: 2.2rem;
                text-align: left;
            }

            & span {
                &._support {
                    color: $colorSub;
                }
            }
        }
    }

    &__colWrapper._main &__col {
        width: 100%;
    }

    &__row._head &__col {
        font-size: 2.6rem;
        color: #fff;
        align-items: center;
    }

    ._cheques &__row._head &__col,
    &._cheques &__row._head &__col {
        @include media(0, $mediaM) {
            font-size: 2.1rem;
            line-height: 1.3;
            display: inline-block;
            width: auto;
            height: auto;
            margin-top: 0;
        }

        &._result {
            @include media(0, $mediaM) {
                display: block;
                width: 100%;
                margin-top: -.2rem;
            }
        }

        &Wrapper {
            @include media(0, $mediaM) {
                display: block;
            }
        }
    }

    ._winners &__col,
    &._winners &__col {
        align-items: center;

        @include media(0, $mediaM) {
            align-items: flex-start;
        }

        &Wrapper {
            &._main {
                width: 25%;

                @include media(0, $mediaM) {
                    width: 30%;
                }
            }

            &._other {
                width: 75%;

                @include media(0, $mediaM) {
                    width: 65%;
                }
            }
        }

        &._email {
            width: 30%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &._phone {
            width: 30%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &._prize {
            width: 40%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }
    }

    ._cheques &__col,
    &._cheques &__col {
        align-items: center;

        @include media(0, $mediaM) {
            align-items: flex-start;
        }

        &Wrapper {
            &._main {
                width: 20%;

                @include media(0, $mediaM) {
                    width: 25%;
                }
            }

            &._other {
                width: 80%;

                @include media(0, $mediaM) {
                    width: 70%;
                }
            }
        }

        &._fd {
            width: 20%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &._status {
            width: 35%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }

        &._result {
            width: 45%;

            @include media(0, $mediaM) {
                width: 100%;
            }
        }
    }
}