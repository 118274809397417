.button {
    @extend %reset;
    @extend %row;
    @extend %click;
    width: 100%;
    height: 100%;
    font-size: 2.1rem;
    line-height: 1.3;
    border-radius: 36rem;
    padding: 1rem 1.8rem .7rem 1.8rem;
    border: 2px solid transparent;
    color: #fff;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include button(.97);
    position: relative;
    z-index: 1;
    overflow: hidden;

    @include media(0, $mediaM) {
        font-size: 2.7rem;
        padding: 1.8rem 3rem 1.5rem 3rem;
    }

    &._minSize {
        font-size: 1.9rem;
        padding: 1.2rem 1rem .7rem 1rem;

        @include media(0, $mediaM) {
            font-size: 2.5rem;
            padding: 1.3rem 1rem .8rem 1rem;
        }
    }

    &._mediumSize {
        font-size: 2.4rem;

        @include media(0, $mediaM) {
            font-size: 2.7rem;
        }
    }

    &._bigSize {
        font-size: 2.8rem;
        padding: 2.5rem 3rem 2.2rem 3rem;

        @include media(0, $mediaM) {
            font-size: 2.6rem;
            padding: 1.7rem 3rem 1.5rem 3rem;
        }
    }

    &._red {
        background: #C20F2F;

        &:hover {
            background: #A80D28;
        }
    }

    &._green {
        background: #00A02F;

        &:hover {
            background: #5FCE55;
        }
    }

    &._purple {
        background: $colorSub;

        &:hover {
            background: #9B32B0;
        }
    }

    &._orange {
        background: #ED7100;

        &:hover {
            background: #FAC913;
        }
    }

    &._darkEmpty {
        border-color: $colorDark;
        color: $colorDark;
        @include loaderColor($colorDark);

        &:hover {
            border-color: $colorMain;

            .button__content {
                color: $colorMain;
            }
        }
    }

    &._darkOrange {
        border-color: $colorDark;
        color: $colorDark;
        background: #FAC913;

        @include loaderColor($colorDark);

        &:hover {
            background: #FFD300;
            border-color: #FFD300;
        }
    }

    &__content {
        @extend %reset;
        transition: $trans;
    }

    &._loader &__content {
        opacity: 0;
    }

    &__loader {
        @extend %reset;
        @extend %loader;
        background: inherit;
        z-index: 100;

        &Item {
            @extend %reset;
            @extend %loaderItem;
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    &._mediumSize &__loaderItem {
        font-size: 3.5rem;
        height: 3.5rem;
    }

    &._bigSize &__loaderItem {
        font-size: 4rem;
        height: 4rem;
    }
}