.waitHeader {
    @extend %reset;
    @extend %section;
    width: 100%;
    height: 103rem;
    @include bg('../media/image-zagl.png', 100% auto, left top);
    position: relative;

    @include media(0, $mediaM) {
        @include bg('../media/image-zagl-mob.png', 100% auto, left top);
        height: 80rem;
    }

    &::before {
        @extend %reset;
        content: "";
        position: absolute;
        top: 3.5rem;
        left: 4rem;
        width: 12.5rem;
        height: 10rem;
        @include bg('../media/logo-mf.svg', contain, left top);
        z-index: 2;

        @include media(0, $mediaM) {
            left: 50%;
            top: 2rem;
            transform: translate(-50%, 0);
            width: 10rem;
            height: 10rem;
        }
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        height: 100%;
    }

    &__content {
        @extend %reset;
        @extend %col;
        margin: auto 25.5rem 19.5rem auto;
        align-items: center;

        @include media(0, $mediaM) {
            margin: 11rem auto 0 auto;
        }
    }

    &__title {
        @extend %reset;
        @extend %row;
        max-width: 100%;
        height: 10.5rem;
        background: #FFD300;
        border-radius: 2.2rem;
        border: 3px solid #721185;
        justify-content: center;
        align-items: center;
        padding: 1.3rem 2.5rem 1rem 2.5rem;
        font-size: 3.4rem;
        color: #C1022F;
        margin-bottom: 2.5rem;

        @include media(0, $mediaM) {
            font-size: 2.6rem;
            height: 8rem;
            padding-top: 1.5rem;
            border-radius: 1.5rem;
            border-width: 2px;
            margin-bottom: 1.5rem;
        }

        & span {
            color: #721185;

            &._counter {
                font-size: 6.4rem;
                margin: 0 1.5rem 0 3rem;

                @include media(0, $mediaM) {
                    font-size: 5.5rem;
                    margin: 0 1rem 0 2rem;
                }
            }
        }
    }

    &__description {
        @extend %reset;
        max-width: 100%;
        text-align: center;
        font-size: 2.4rem;
        color: #97C124;

        @include media(0, $mediaM) {
            font-size: 2.2rem;
        }

        & span {
            color: #721185;
        }
    }
}