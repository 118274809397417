.indexWinners {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 3rem 0 9.5rem 0;
    position: relative;

    @include media(0, $mediaM) {
        padding: 12rem 0 15rem 0;
        // padding-bottom: 50rem;
        @include bg('../media/back-mob-5.png', 100% auto, center top);
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 128rem);
        @include bg('../media/back-5.png', 100% auto, center bottom);
        background-repeat: repeat-y;
        z-index: 2;
        pointer-events: none;

        @include media(0, $mediaM) {
            height: calc(100% - 203rem);
            @include bg('../media/back-mob-6.png', 100% auto, center bottom);
            background-repeat: repeat-y;
        }
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        align-items: center;
        position: relative;
        z-index: 3;
    }

    &__head {
        @extend %reset;
        @extend %col;
        width: 88rem;
        padding-top: 12rem;
        position: relative;
        z-index: 2;
        align-items: center;
        margin-bottom: 5rem;

        @include media(0, $mediaM) {
            width: 100%;
            padding-top: 11.5rem;
            margin-bottom: 4.5rem;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            width: 65rem;
            height: 40rem;
            @include bg('../media/flag-4.png', contain, center top);
            z-index: -1;

            @include media(0, $mediaM) {
                width: 43rem;
            }
        }

        &Title {
            @extend %reset;
            font-size: 2.6rem;
            line-height: 1.2;
            color: $colorDark;
            margin-bottom: 6.1rem;

            @include media(0, $mediaM) {
                font-size: 2.9rem;
            }

            &Link {
                @extend %reset;
                @extend %click;
                color: $colorSub;
                border-bottom: 1px solid rgba($colorSub, .64);
                display: inline-block;
                @include button();
            }
        }

        &Filter {
            @extend %reset;
            @extend %row;
            width: 100%;
            justify-content: space-between;
            align-items: stretch;

            @include media(0, $mediaM) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }

            &Block {
                @extend %reset;
                @extend %col;
                width: calc(50% - 1.25rem);

                @include media(0, $mediaM) {
                    width: 100%;

                    &+& {
                        margin-top: 4.5rem;
                    }
                }

                &Content {
                    @extend %reset;
                    width: 100%;
                    height: 5.75rem;
                    position: relative;
                    margin-bottom: 2.7rem;

                    @include media(0, $mediaM) {
                        height: 7.5rem;
                        margin-bottom: 1.3rem;
                    }
                }

                &Support {
                    @extend %reset;
                    max-width: 100%;
                    font-family: 'Inter';
                    font-size: 1.55rem;
                    color: $colorDark;
                    margin-top: auto;

                    @include media(0, $mediaM) {
                        font-size: 1.8rem;
                    }
                }
            }

            &Input {
                @extend %reset;
                width: 100%;
                height: 100%;
                padding: 1.3rem 1rem 1rem 5.3rem;
                font-size: 2.6rem;
                color: $colorDark;
                background: #fff;
                border-radius: .5rem;

                @include media(0, $mediaM) {
                    font-size: 3rem;
                    padding-left: 6.3rem;
                    padding-top: 1.5rem;
                }

                &::-webkit-input-placeholder {
                    color: $colorDark;
                }

                &Icon {
                    @extend %reset;
                    position: absolute;
                    top: 50%;
                    left: 1.75rem;
                    transform: translate(0, -50%);
                    width: 2rem;
                    height: 2rem;
                    margin-top: -.1rem;

                    @include media(0, $mediaM) {
                        left: 1.9rem;
                        width: 2.2rem;
                        height: 2.2rem;
                    }
                }
            }
        }
    }

    &__table {
        @extend %reset;
        width: 100%;
        padding: 0 7rem;
        margin-bottom: 5rem;
        position: relative;
        z-index: 1;

        @include media(0, $mediaM) {
            border-radius: 1rem;
            padding: 2rem;
            background: #fff;
        }
    }

    &__button {
        @extend %reset;
        width: 35rem;

        @include media(0, $mediaM) {
            width: 27rem;
        }
    }
}