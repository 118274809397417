.select {
    @extend %reset;
    width: 100%;
    height: 100%;
    position: relative;

    &__view {
        @extend %reset;
        @extend %row;
        @extend %click;
        width: 100%;
        height: 100%;
        background: #fff;
        padding: 1.3rem 4rem 1rem 2rem;
        position: relative;
        align-items: center;
        border-radius: .5rem;
        @include button(.98);

        @include media(0, $mediaM) {
            padding: 1.5rem 4rem 1rem 2.2rem;
        }

        &Value {
            @extend %reset;
            width: 100%;
            font-size: 2.6rem;
            color: $colorDark;

            @include media(0, $mediaM) {
                font-size: 3rem;
            }
        }

        &Icon {
            @extend %reset;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 2rem;
            width: 2rem;
            height: 2rem;
            transition: $trans;

            @include media(0, $mediaM) {
                width: 2.5rem;
                height: 2.5rem;
                right: 2.2rem;
            }
        }
    }

    &._main &__view {
        border-radius: 1rem;
        background: rgba($colorBack, .8);
        padding-top: 1.5rem;

        @include media(0, $mediaM) {
            padding-left: 1.5rem;
        }

        &Value {
            color: $colorSub;
            font-size: 2.3rem;

            @include media(0, $mediaM) {
                font-size: 2.7rem;
            }
        }

        &Icon {
            width: 1.7rem;
            height: 1.7rem;
            @include icon($colorDark);
            right: 1.5rem;
        }
    }

    &._active &__viewIcon {
        transform: translate(0, -50%) rotate(180deg);
    }

    &__list {
        @extend %reset;
        position: absolute;
        top: 100%;
        margin-top: 1rem;
        padding: 1rem 0;
        left: 0;
        z-index: 10;
        width: 100%;
        max-height: 20rem;
        overflow-y: auto;
        transition: $trans;
        background: #fff;
        box-shadow: 0 0 1.2rem rgba(#61636A, .15);
        border-radius: .5rem;

        &:not(._show) {
            transform: translate(0, .5rem);
            opacity: 0;
        }

        &Item {
            @extend %reset;
            @extend %click;
            padding: 1rem 2rem;
            font-size: 2.6rem;
            color: $colorDark;
            transition: $trans;
            @include button();

            @include media(0, $mediaM) {
                font-size: 3rem;
                padding: 1.2rem 2.2rem;
            }

            &:hover {
                color: $colorSub;
            }
        }
    }

    &._main &__list {
        border-radius: 1rem;
        background: #fff;
        max-height: 15rem;

        &Item {
            font-size: 2.3rem;
            color: $colorSub;

            @include media(0, $mediaM) {
                font-size: 2.7rem;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }
}