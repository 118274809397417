.faqForm {
    @extend %reset;
    @extend %section;
    width: 100%;
    padding: 8rem 0 20rem 0;

    &._anket {
        padding-top: 0;

        @include media(0, $mediaM) {
            padding-top: 0;
        }
    }

    @include media(0, $mediaM) {
        padding: 5rem 0 20rem 0;
    }

    &__inner {
        @extend %reset;
        @extend %inner;
        align-items: center;
    }

    &__head {
        @extend %reset;
        width: 100%;
        margin-bottom: 7rem;

        @include media(0, $mediaM) {
            margin-bottom: 3.5rem;
        }
    }

    &._anket &__head {
        margin-bottom: 3rem;
    }

    &__content {
        @extend %reset;
        @extend %col;
        width: 100%;
        align-items: center;

        &Inner {
            @extend %reset;
            width: 100%;
        }
    }

    &._anket &__content {
        max-width: 88rem;
    }
}